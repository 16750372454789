import { memo, useEffect, useState } from "react";
import { PageBox, Nav } from "../../components";
import { color, size } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts";
import { MyText } from "../../components/MyText";
import *as imgs from "../../imgs";
import { I18n } from "react-redux-i18n";








const TextDataZhTw: string[] = [
    '地址：九龍橫頭磡聯合道198號樂富廣場Zone A 3樓3198號店舖 （簡簡單單ecLiving 旁邊）',
    '服務時段：  2023年4月23日至7月21日',
    '服務日期： 星期一至星期日',
    '服務時間： 早上11時至下午7時30分',
    'Whatsapp 號碼： 9869 3657 ',
]

const TextDataZhCn: string[] = [
    '地址：九龙横头磡联合道198号乐富广场Zone A 3楼3198号店铺 （简简单单ecLiving 旁边）',
    '服务时段：2023年4月23日至7月21日',
    '服务日期：星期一至星期日',
    '服务时间：早上11时至下午7时30分',
    'Whatsapp 号码： 9869 3657 ',
]


const TextDataEn: string[] = [
    'Address: Shop 3187, 3/F, Zone A, Lok Fu Place, 198 Junction Road, Wang Tau Hom, Kowloon (next to ecLiving)',
    'Service period: 23 April to 21 July 2023',
    'Service Days: Monday to Sunday',
    'Service Hours: 11am to 7:30pm',
    'Whatsapp number: 9869 3657',
]







const TextData = {
    'zh-tw': TextDataZhTw,
    'zh-cn': TextDataZhCn,
    'en': TextDataEn,
}


/**
 * 米仔币
 */
export const MiziCoinInfo = memo(() => {

    const dispatch = useAppDispatch()
    const config = useAppSelector(state => state.config)

    useEffect(() => {
        (async () => {

        })()
    }, [])

    return (
        <div
            style={{
                flex: 1,
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                paddingBottom: 80,
            }}
        >
            <div>
                <div style={{
                    width: '100%',
                    height: 180,
                    background: '#ddd',
                }}></div></div>

            <MyText
                type='a2'
                style={{
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 5,
                    marginBottom: 8,
                    fontWeight: '600',
                    color: color.mainB,
                }}

            >
                {I18n.t('MiziCoinInfo__sponsor')}
            </MyText>

            <img
                src={imgs.linkLogo}
                style={{
                    width: '60%',
                    marginLeft: 20,
                    marginBottom: 8,
                }}
            />

            {TextData[config.language].map((text, index) => {
                return (
                    <MyText
                        type='a1'
                        key={index}
                        style={{
                            marginLeft: 20,
                            marginRight: 20,
                            marginTop: 5,
                            color: color.black_2,
                            fontWeight: '500',
                        }}
                    >
                        {text}
                    </MyText>
                )
            })}
        </div>
    )
})
